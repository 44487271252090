<template>
	<user-order-detail-card v-if="order" :order="order" :orderItems="orderItems" />
</template>

<script>
import { mapActions } from "vuex";
import UserOrderDetailCard from "./UserOrderDetailCard.vue";

export default {
	components: {
		UserOrderDetailCard,
	},
	props: {
		orderId: {
			type: Number,
		},

		redirectName: {
			type: String,
			required: true,
		},
	},
	async created() {
		if (!this.orderId) {
			this.$router.replace({ name: this.redirectName });
			return;
		}
		const orderFound = await this.getOrder(this.orderId).catch(() => {
			return null;
		});
		if (!orderFound) {
			this.$router.replace({ name: "UserOrders" });
			return;
		}
		this.order = orderFound;

		this.getOrderItems(this.orderId).then((data) => {
			this.orderItems = [...data];
		});
	},
	data() {
		return {
			order: null,
			orderItems: [],
		};
	},
	methods: {
		...mapActions({
			getOrder: "user/orders/getOrder",
			getOrderItems: "user/orders/getOrderItems",
		}),
	},
};
</script>

<style></style>
