<template>
	<div class="d-flex m-2" v-if="isStorePresent">
		<b-img v-if="storeImage" :src="storeImage.blobUrl" width="50%" class="m-2" />
		<div class="flex-fill align-self-center ml-2">
			<div class="d-flex justify-content-between">
				<h5>{{ store.storeName }}</h5>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["store"],
	computed: {
		isStorePresent() {
			if (this.store) {
				return true;
			}
			return false;
		},
		storeImage() {
			return this.store.storeImage;
		},
	},
};
</script>
