<template>
	<b-card>
		<b-card-header>
			<store-image-and-name :store="order.store" />
		</b-card-header>

		<br />

		<!-- Order Data -->
		<div>
			<b-row align-h="between" class="mx-2">
				<!-- Order -->
				<p class="font-weight-bold">Order #{{ order.id }}</p>

				<!-- Order Status -->
				<p class="text-capitalize">
					{{ statusToDisplay }}
				</p>
			</b-row>

			<b-row class="mx-2">
				<!-- Date -->
				<p>{{ createdTimeDate }}</p>
			</b-row>
		</div>

		<hr />

		<!-- Order Prescriptions -->
		<order-prescriptions :order="order" :isMerchant="false" />

		<!-- Items -->
		<div>
			<!-- Item Count -->
			<p class="text-uppercase text-muted mx-2">{{ itemCountText }}</p>

			<!-- ItemCard -->
			<order-item v-for="item in orderItems" :key="item.id" :orderId="order.id" :orderItem="item" />
		</div>

		<hr />

		<!-- Costing -->
		<div>
			<!-- Items Price -->
			<b-row align-h="between" class="mx-2">
				<!-- Item Total Text -->
				<p>Item Total</p>

				<!-- Items Price -->
				<p>{{ order.totalItemsPrice.toString() | rupeeCurrency }}</p>
			</b-row>

			<!-- Delivery -->
			<b-row align-h="between" class="mx-2">
				<!-- Delivery Text -->
				<p>Delivery</p>

				<!-- Delivery -->
				<p>{{ order.deliveryCost.toString() | rupeeCurrency }}</p>
			</b-row>

			<!-- Grand Total -->
			<b-row align-h="between" class="mx-2">
				<!-- Grand Total Text -->
				<h5 class="font-weight-bold">Grand Total</h5>

				<!-- Total Amount -->
				<h5 class="font-weight-bold">
					<template v-if="hasItemWithNoPrice"> {{ grandTotal | rupeeCurrency }} + {{ "**" | rupeeCurrency }} </template>
					<template v-else>
						{{ grandTotal | rupeeCurrency }}
					</template>
				</h5>
			</b-row>

			<b-row v-if="hasItemWithNoPrice">
				<b-col>
					<market-price class="mt-3" />
				</b-col>
			</b-row>
		</div>

		<hr />
		<!-- CUSTOMER DETAILS -->
		<order-customer-detail v-if="order && order.customerDetail" :customerDetail="order.customerDetail" />

		<!-- Order Again -->
		<hr />
		<div class="text-center">
			<b-button variant="primary" @click="orderAgain">Order Again</b-button>
		</div>
	</b-card>
</template>

<script>
import dateformat from "dateformat";
import { mapActions } from "vuex";

import OrderItem from "./OrderItem.vue";
import MarketPrice from "../common/MarketPrice.vue";
import StoreImageAndName from "../../components/store/StoreImageAndName.vue";
import OrderPrescriptions from "../prescriptions/OrderPrescriptions.vue";
import OrderCustomerDetail from "./OrderCustomerDetail.vue";

export default {
	components: { OrderItem, MarketPrice, StoreImageAndName, OrderPrescriptions, OrderCustomerDetail },
	props: {
		order: {
			type: Object,
			required: true,
		},
		orderItems: {
			type: Array,
			required: true,
		},
	},
	computed: {
		// subtitle() {
		// 	const statusToDisplay = this.statusToDisplay;
		// 	return "Order Details".concat(" (status: " + statusToDisplay + ")");
		// },
		statusToDisplay() {
			const { orderStatus } = this.order;
			const filters = this.$options.filters;
			return filters.orderStatusToDisplay(orderStatus);
		},

		createdTimeDate() {
			const { createdTime } = this.order;
			const date = new Date(createdTime);
			return dateformat(date, process.env.VUE_APP_DATE_TIME_FORMAT);
		},

		itemCountText() {
			const itemCount = this.orderItems.length;
			if (itemCount) {
				if (itemCount == 1) {
					return "1 item";
				}
				return `${itemCount} items`;
			}
			return "No items";
		},

		hasItemWithNoPrice() {
			return this.order.hasItemWithNoPrice;
		},
		grandTotal() {
			return this.order.orderAmount.toString();
		},

		customerMobile() {
			const customerDetail = this.order.customerDetail;
			if (customerDetail) {
				return customerDetail.customerMobile;
			}
			return null;
		},
		userAddress() {
			const customerDetail = this.order.customerDetail;
			if (customerDetail) {
				const address = {
					pincode: customerDetail.pincode,
					city: customerDetail.city,
					area: customerDetail.area,
					otherDetails: customerDetail.otherDetails,
					landmark: customerDetail.landmark,
					state: customerDetail.state,
					latitude: customerDetail.latitude,
					longitude: customerDetail.longitude,
				};
				return address;
			}
			return null;
		},
		addressLine() {
			const userAddress = this.userAddress;
			if (userAddress) {
				const otherDetails = userAddress.otherDetails;
				const area = userAddress.area;
				const landmark = userAddress.landmark;

				let arrayOfAddressDetail = [];
				if (otherDetails) {
					arrayOfAddressDetail.push(otherDetails);
				}
				if (area) {
					arrayOfAddressDetail.push(area);
				}
				if (landmark) {
					arrayOfAddressDetail.push(landmark);
				}
				return arrayOfAddressDetail.join(", ");
			}
			return null;
		},
		city() {
			if (this.userAddress) {
				return this.userAddress.city;
			}
			return null;
		},
		pincode() {
			if (this.userAddress) {
				return this.userAddress.pincode;
			}
			return null;
		},
	},
	methods: {
		...mapActions({
			reorder: "user/orders/reorder",
		}),
		orderAgain() {
			this.reorder(this.order.id).then(() => {
				this.$router.push({
					name: "Cart",
					params: {
						storeUrlName: this.order.store.storeUrlName,
					},
				});
			});
		},
	},
};
</script>

<style></style>
