<template>
	<order-detail-component :orderId="orderId" redirectName="StoreOrders" />
</template>

<script>
import OrderDetailComponent from "../../../components/orders/OrderDetailComponent.vue";

export default {
	components: {
		OrderDetailComponent,
	},
	data() {
		return {
			orderId: this.$route.params.orderId,
		};
	},
};
</script>
